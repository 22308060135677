import {WIN, ACTIVE} from '../constants';

const logo = $('.js-logo');

// let start = WIN.scrollTop();

WIN.on('load scroll', () => {
  console.log('added scroll logo 44555');
  // let current = WIN.scrollTop();
  WIN.scrollTop() > 0 ? logo.addClass(ACTIVE) : logo.removeClass(ACTIVE);
  // start < current ? logo.addClass(ACTIVE) : logo.removeClass(ACTIVE);
  // start = current;
});
